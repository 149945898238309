import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0f021968"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "nav-ul"
};
var _hoisted_2 = ["onClick"];
import { ref } from 'vue';
import router from '@/router';
export default {
  setup: function setup(__props) {
    var menuList = ref([{
      id: 2,
      name: 'subject',
      title: '初中数学',
      color: '#ffbe0f',
      icon: 'CircleClose'
    }, {
      id: 1,
      name: 'subject',
      title: '经典语文',
      color: '#a286c0',
      icon: 'Notebook'
    }, {
      id: 3,
      name: 'subject',
      title: '趣味英语',
      color: '#f45b5c',
      icon: 'EditPen'
    }, {
      id: 4,
      name: 'subject',
      title: '初中物理',
      color: '#6fb906',
      icon: 'Magnet'
    }, {
      id: 5,
      name: 'subject',
      title: '初中化学',
      color: '#48c5e5',
      icon: 'MagicStick'
    }, {
      id: 7,
      name: 'subject',
      title: '高中数学',
      color: '#f59024',
      icon: 'Crop'
    }, {
      id: 9,
      name: 'subject',
      title: '小学数学',
      color: '#3aa2e6',
      icon: 'CirclePlus'
    }, {
      id: 8,
      name: 'package',
      title: '套餐',
      color: '#ff6698',
      icon: 'Box'
    }]);
    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      return _openBlock(), _createElementBlock("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, function (item) {
        return _openBlock(), _createElementBlock("li", {
          key: item.id,
          onClick: function onClick($event) {
            return _unref(router).push({
              name: item.name,
              params: {
                id: item.id
              }
            });
          }
        }, [_createElementVNode("span", {
          class: "background-circle",
          style: _normalizeStyle('background-color:' + item.color)
        }, [_createVNode(_component_el_icon, null, {
          default: _withCtx(function () {
            return [(_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))];
          }),
          _: 2
        }, 1024)], 4), _createElementVNode("span", null, _toDisplayString(item.title), 1)], 8, _hoisted_2);
      }), 128))]);
    };
  }
};