import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2e55838c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "show"
};
var _hoisted_2 = ["href"];
var _hoisted_3 = ["src"];
import { ref, onUpdated, watch } from 'vue';
export default {
  props: {
    bannerList: Array,
    loadingFlag: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    var loading = ref(true);
    watch(function () {
      return props.loadingFlag;
    }, function (v) {
      loading.value = v;
    }, {
      deep: true,
      immediate: true
    });
    onUpdated(function () {
      loading.value = false;
    });
    return function (_ctx, _cache) {
      var _component_el_carousel_item = _resolveComponent("el-carousel-item");

      var _component_el_carousel = _resolveComponent("el-carousel");

      var _directive_loading = _resolveDirective("loading");

      return loading.value ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [[_directive_loading, loading.value]]) : (_openBlock(), _createBlock(_component_el_carousel, {
        key: 1,
        trigger: "click",
        arrow: "never",
        height: "50vw",
        interval: "3000"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.bannerList, function (item) {
            return _openBlock(), _createBlock(_component_el_carousel_item, {
              key: item.id
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("a", {
                  href: item.url
                }, [_createElementVNode("img", {
                  src: item.img,
                  alt: "item.title"
                }, null, 8, _hoisted_3)], 8, _hoisted_2)];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      }));
    };
  }
};