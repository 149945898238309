import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2ffd531e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onClick"];
var _hoisted_2 = ["src", "alt"];
var _hoisted_3 = {
  class: "right"
};
var _hoisted_4 = {
  class: "title"
};
import { ref } from 'vue';
import VideoPlayer from '@/components/common/video.vue';
export default {
  props: {
    videoData: Array
  },
  setup: function setup(__props) {
    var props = __props;
    var dialogVisible = ref(false);
    var currentVideo = ref({});

    var playVideo = function playVideo(id) {
      dialogVisible.value = true;
      currentVideo.value = id;
    };

    return function (_ctx, _cache) {
      var _component_star = _resolveComponent("star");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_video_play = _resolveComponent("video-play");

      var _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.videoData, function (item) {
        return _openBlock(), _createElementBlock("li", {
          key: item.vid,
          onClick: function onClick($event) {
            return playVideo(item.pl_id);
          }
        }, [_createElementVNode("img", {
          src: item.img,
          alt: item.title
        }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1), _createElementVNode("p", null, [_createElementVNode("span", null, [_createVNode(_component_el_icon, null, {
          default: _withCtx(function () {
            return [_createVNode(_component_star)];
          }),
          _: 1
        }), _createTextVNode(_toDisplayString(item.favourable), 1)]), _createElementVNode("span", null, [_createVNode(_component_el_icon, null, {
          default: _withCtx(function () {
            return [_createVNode(_component_video_play)];
          }),
          _: 1
        }), _createTextVNode(_toDisplayString(item.islearning), 1)])])])], 8, _hoisted_1);
      }), 128))]), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return dialogVisible.value = $event;
        }),
        "destroy-on-close": "true",
        width: "100%"
      }, {
        default: _withCtx(function () {
          return [_createVNode(VideoPlayer, {
            "video-id": currentVideo.value
          }, null, 8, ["video-id"])];
        }),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};